import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <footer>
      <a href="#" className="footer_logo">
        Andres Valdez
      </a>
      <ul className="permalinks">
        <li>
          <a href="#">Home</a>
        </li>
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#experience">Experience</a>
        </li>
        <li>
          <a href="#contact">Contact</a>
        </li>
      </ul>
      {/* <div className="footer_socials">
        <a href="https://facebook.com"></a>
        <a href="https://instagram.com"></a>
        <a href="https://twitter.com"></a>
      </div> */}
      <div className="made-with">
        <h3>{`{ Made with React.js }`}</h3>
      </div>
      <div className="footer_copyright">
        <small>&copy; andresevaldez | All rights reserved.</small>
      </div>
    </footer>
  );
};

export default Footer;
