import React from 'react';
import CV from '../../assets/cv.pdf';

const CTA = () => {
  return (
    <div className="cta">
      {/* <a href={CV} download className="btn">
        Download CV
      </a> */}
      <a
        href="https://www.linkedin.com/in/andres-valdez-34027597/"
        className="btn btn-primary"
      >
        Let's Connect
      </a>
    </div>
  );
};

export default CTA;
