import React from 'react';
import './about.css';

import imgOfMe from '../../assets/andres_about-me.jpeg';

import { FaAward } from 'react-icons/fa';
import { FiUsers } from 'react-icons/fi';
import { VscFolderLibrary } from 'react-icons/vsc';

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about_container">
        <div className="about_me">
          <div className="about_me-image">
            <img src={imgOfMe} alt="About image" className="about_img"></img>
          </div>
        </div>
        <div className="about_content">
          <div className="about_cards">
            <article className="about_card">
              <FaAward className="about_icon" />
              <h5>Experience</h5>
              <small>2 Year Working</small>
            </article>
            {/* <article className="about_card">
              <FiUsers className="about_icon" />
              <h5>Courses</h5>
              <small>6 Completed</small>
            </article> */}
            <article className="about_card">
              <VscFolderLibrary className="about_icon" />
              <h5>Projects & Tasks</h5>
              <small>25+ Completed</small>
            </article>
          </div>
          <p>
            Electrical-turned-Software Engineer with affinity for software
            development. As a Software Engineer, I am ambitious, organized, and
            overall, enthusiastic. My approach is to never stop learning.
          </p>

          <a
            href="https://www.linkedin.com/in/andresevaldez/"
            className="btn btn-primary"
          >
            Let's Connect
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
