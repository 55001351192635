import React from 'react';
import './header.css';

import CTA from './CTA';
import HeaderSocials from './HeaderSocials';

import imageOfMe from '../../assets/andres.png';

const Header = () => {
  return (
    <header>
      <div className="container header_container">
        <h5>Hello I'm</h5>
        <h1>Andres Valdez</h1>
        <h5 className="text-light">Software Engineer</h5>
        <CTA />
        <HeaderSocials />

        <div className="made-with">
          <h3>{`{ Made with React.js }`}</h3>
        </div>

        <div className="me">
          <img src={imageOfMe} alt="This is me!" className="header_img"></img>
        </div>

        <a href="#contact" className="scroll_down">
          Scroll Down
        </a>
      </div>
    </header>
  );
};

export default Header;
